import React from 'react'
import PropTypes from 'prop-types'
import contentfulImage from 'lib/contentful-image'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import { linkPropType } from '../../../lib/prop-types'

const LinkingImage = props => {
  return (
    <a href={contentfulUrlForLink(props.link)}>
      <img
        key={`${props.id}-${props.Image.url}`}
        src={contentfulImage(props.Image.url)}
        alt={props.Image.description}
      />
    </a>
  )
}

LinkingImage.propTypes = {
  id: PropTypes.string,
  Image: PropTypes.shape({
    description: PropTypes.string,
    url: PropTypes.string,
  }),
  link: linkPropType,
}

export default LinkingImage
