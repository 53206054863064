import React from 'react'
import PropTypes from 'prop-types'
import { locationPropType } from 'lib/prop-types'
import Markdown from 'components/Markdown'
import contentfulImage from 'lib/contentful-image'

function makeCallable(phoneNumber) {
  if (!phoneNumber) {
    return ''
  }
  return '+41' + phoneNumber.substring(1).replace(/ /g, '')
}

const Locations = props => {
  const mapImageUrl = contentfulImage(props.map.url, {
    width: 1100,
    quality: 75,
  })

  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.text}
        </Markdown>
        <div className="locations__wrapper">
          <div className="location__map">
            <img src={mapImageUrl} alt={props.map.description} />
          </div>
          <div className="locations">
            {props.locations.map((location, i) => {
              return (
                <div key={`location-${i}`} className="location__box">
                  <div className="nowrap">{location.street}</div>
                  <div>
                    {location.zipCode} {location.locality}
                  </div>
                  <a
                    href={`tel:${makeCallable(location.phoneNumber)}`}
                    className="location__phone"
                  >
                    {location.phoneNumber}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

Locations.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  map: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  locations: PropTypes.arrayOf(locationPropType),
}

export default Locations
