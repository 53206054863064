import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components/Markdown'
import contentfulImage from 'lib/contentful-image'
import LinkingImage from 'components/Contentful/LinkingImage'

const renderLogos = (id, logos, logosWithLink) => {
  return [
    ...logos.map(logo => {
      return (
        <img
          key={`${id}-${logo.url}`}
          src={contentfulImage(logo.url, { quality: 75 })}
          className="brands-box--item"
          alt={logo.description}
        />
      )
    }),
    ...logosWithLink.map(logo => (
      <div className="brands-box--item" key={logo.id}>
        <LinkingImage {...logo} />
      </div>
    )),
  ]
}

const Brands = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.description}
        </Markdown>
        <div className="brands-box">
          {props.logos || props.logosWithLink
            ? renderLogos(
                props.id,
                props.logos || [],
                props.logosWithLink || []
              )
            : null}
        </div>
      </div>
    </div>
  )
}

Brands.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  logos: PropTypes.array,
  logosWithLink: PropTypes.array,
}

export default Brands
