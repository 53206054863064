import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Button from 'components/Button'

const Highlight = props => {
  const button = props.link ? (
    <Button className="btn--outlined" link={props.link}>
      {props.link.label}
    </Button>
  ) : null

  const image = props.image ? (
    <div className="hm-highlight--img">
      <img src={props.image.url} alt={props.image.description} />
    </div>
  ) : null
  return (
    <div className="hm-highlight">
      {image}
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      {button}
    </div>
  )
}

Highlight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  link: linkPropType,
}

export default Highlight
