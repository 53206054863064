import React from 'react'
import PropTypes from 'prop-types'
import Highlight from 'components/Contentful/Highlight'
import Markdown from 'components/Markdown'

const Highlights = props => {
  const highlights = props.highlights.length > 0 ? props.highlights : []

  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.description}
        </Markdown>
        <div className="highlights">
          {highlights.map((highlight, i) => {
            return (
              <Highlight
                key={`${props.id}-${highlight.id}-${i}`}
                {...highlight}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

Highlights.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.shape(Highlight.propTypes)),
}

export default Highlights
